
@mixin flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@mixin margin-auto{
    margin: 0 auto;
}

@mixin nastav-pozadie-obr {
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

@mixin animacia-pozadia {


    animation: change 20s infinite ease-in-out;    
}

@mixin nastred {
    text-align: center;
}

@mixin hlavne-pismo($rozmer) {
    color: $farba-pismo;
    font-size: $rozmer;
    text-shadow: 1px 1px black;
}

@mixin ramcek($sirka:1px) {
    border: $sirka solid $farba-pismo;
    box-shadow: 1px 2px black;
    border-radius: $zaoblenie;
}

@mixin max-sirka ($sirka) {
    max-width: $sirka;
}