@import "../sass/premenne";
@import "../sass/funkcie";

nav a {
    margin: 10px;
    padding: 20px 20px;
    min-width: 180px;
    display: inline-block;
    min-height: 30px;
    text-decoration: none;
    color: $faPismo;
    background-color: $faPismoPoz;
    text-align: left;
}