@import "./sass/premenne";
@import "./sass/funkcie";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header{
    @include flex;
    @include margin-auto;
}

body{
    background-color: $faPodklad;
    
}

footer{
    @include flex;
    height: 50px;
    margin: 0 auto;
}

.sirkahome{
    @include flex;
    height: 80vh;
}

h1 {
    color: $faPismo;
}
h2 {
    color: rgb(48, 30, 210);
}